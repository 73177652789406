<template>
  <div class="loginAs-panel">
    <div class="loginAs-panel-logo d-flex justify-content-center">
      <img alt="vision" src="@/assets/images/logo-vision.svg">
    </div>
    <p class="modal-alert modal-alert_advice text-center">Please make sure, you are not already logged in to
      <a class="text-decoration-none" href="https://clearmortgagecapital.com/mlo/">clearmortgagecapital.com</a> on this
      Browser</p>
    <div class="loginAs-panel-wrapper">
      <div class="text-center">
        <div class="loginAs-panel-subtitle mb-3">Consider to use incognito or private window</div>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <br>
    <div class="d-flex justify-content-center align-items-center">
      <div class="powered-by">
        Powered by
      </div>
      <img alt="vision" src="@/assets/images/logo-clearmortgagecapital.svg">
    </div>
    <p v-if="error" class="text-center mt-4 mb-0 color-red">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'LoginAs',
  data() {
    return {
      error: null
    }
  },
  methods: {
    redirect() {
      this.$http.get(`/api/v1/auth/login-as/${this.$route.params.currentUserToken}/${this.$route.params.id}`)
        .then((res) => {
          this.$store.dispatch('SET_USER', res.data)
          window.location.replace('/')
        })
        .catch((err) => {
          this.error = err.response.data ? err.response.data : err.response.statusText
        })
    }
  },
  created() {
    setTimeout(() => {
      this.redirect()
    }, 3000)
  }
}
</script>
<style lang="scss" scoped>
.loginAs-panel {
  min-height: 540px;
  max-width: 440px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.06);
  margin: 50px auto;
  padding: 30px;

  &-subtitle {
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  }

  &-logo {
    padding-bottom: 24px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 24px;
  }

  &-wrapper {
    padding-bottom: 30px;
    border-bottom: 1px solid #E7E8E8;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .powered-by {
    opacity: 0.46;
    color: #0B1B1C;
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 12px;
  }

  .modal-alert {
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}
</style>
